.tab-content {
  
  width: 100%;
  z-index: 99;
  padding: 9px 0px 9px 0px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.payment-steps {
  width: 100%;
  margin: 0 auto;
}
.tab-pane {
  width: 90%;
  margin: 0 auto;
}

.payment-steps .steps {
  display: flex;
  column-count: 5;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}

.payment-steps .steps .step {
  flex: 0 auto;
  display: flex;
  align-items: center;
}

.payment-count.active {
  background-color: #01147c !important;
  color: #fff !important;
}

.payment-steps .steps .step:last-child:after {
  content: "";
  display: none;
}

.payment-steps .steps .step .payment-count {
  font-weight: 600;
  font-size: 14px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background: #fff;
  border: 2px solid#01147C;
  color: #01147c;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  z-index: 2 !important;
  cursor: pointer !important;
}

.payment-count.payment-detail.active {
  border: 2px solid #01147c !important;
  color: #01147c !important;
}

.step-title.payment-detail.active {
  color: #01147c;
}

.true-img {
  height: 12px;
}

.payment-steps .tabs {
  width: 100%;
  height: 100%;
}

.payment-steps .tabs .tab {
  display: none;
}

.payment-steps .tabs .tab.active {
  display: block !important;
}

.payment-line {
  border-bottom: 1px solid #01147c !important;
 min-height: 1px;
  margin: auto 15px;
  width: 25px;
}
