@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;1,100;1,200&family=Inter:wght@500;700&family=Open+Sans:wght@600&display=swap";

body {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  color: #000000;
}

p {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
}

.pl-8 {
  padding-left: 8px;
}

.ml-2 {
  margin-left: 15px;
}

.form-group span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.form-group.drop-btn span {
  width: 100%;
}

input:-webkit-autofill {
  -webkit-text-background-color: #ffff !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

input:-webkit-autofill:focus {
  -webkit-text-background-color: #ffff !important;
}

input:-webkit-autofill {
  background-color: #ffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #ffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: none;
}

.form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(159, 159, 159) !important;
}

.payment-steps .text-blue {
  color: #01147c;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.form-control:focus {
  color: none;
  background-color: none;
  border: 1px solid rgba(0, 0, 0, 0.26);
  outline: 0;
  box-shadow: none;
  transition: none;
}

.text-header-purple {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #01147c !important;
}

.btn.btn-cancel,
.btn.btn-back {
  background: #ffff;
  border: 1px solid #01147c;
  color: #01147c;
  border-radius: 4px;
  padding: 8px 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  width: 100px;
  font-size: 14px;
}

.btn.btn-cancel:hover,
.btn-back:hover {
  border: 1px solid #01147c;
  background: transparent;
}

.p-button .btn-cancel,
.p-button .btn-back:enabled:hover {
  background: #ffff;
}

.bottom-btns .p-button.btn-back:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #01147c;
  border: 1px solid #01147c;
}

.btn.btn-save {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 35px;
}

.btn.btn-save:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 35px;
}

.btn.btn-continue {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 8px 20px;
  font-weight: 400 !important;
  font-size: 14px;
  min-width: 100px;
  max-width: 200px;
}

.btn.btn-continue:hover {
  background: #01147c;
  border: 1px solid #01147c;
  color: white;
}

.form-group .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: none;
  border-color: none;
}

.btn .p-button:enabled:hover {
  background: transparent;
  color: none;
}

.p-button:enabled:hover {
  background-color: #01147c;
}

.btn.btn-continue:hover {
  background: #01147c !important;
  border: 1px solid #01147c;
  border-radius: 4px;
  color: #ffffff;
}

.btn.btn-ok {
  align-items: center !important;
  margin-left: 162px !important;
}

.color-red {
  color: #eb2929;
}

#content {
  width: 100%;
  height: 100vh;
  transition: all 0.3s;
  background-image: url(../images/bg-img-small.svg),
    url(../images/contrnt-bg-img.svg);
  background-position: bottom left, bottom right;
  background-repeat: no-repeat;
  position: relative;
}

.right-tab-section {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.heading-section {
  position: static;
  display: block;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding-top: 15px;
}

.container-fluid.acc-screen {
  padding-top: 20px;
}

.createpayment-screen .address-heading {
  padding: 0px 45px;
}

.createpayment-screen .sender-info {
  padding-top: 30px;
}

.form-group {
  margin-bottom: 14px;
  position: relative;
}

.partner-btns {
  justify-content: center !important;
  position: relative;
}

.form-control {
  transition: none;
}

.form-control.form-control-sm {
  /* width: 500px;
    height: 38px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 7px;
}

.dropdown-acc input {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 7px !important;
  font-family: "primeicons" !important;
  height: 40px;
}

.pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -6px;
  right: 2px;
  font-family: "primeicons" !important;
  color: #01147c;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  height: 21px;
  width: 100%;
  position: absolute;
  font-family: "IBM Plex Sans" !important;
  right: 0px;
  top: 5px;
  padding-right: 10px;
  border: 0px solid #01147c !important;
  border-radius: 2px !important;
  background-color: transparent;
}

.p-autocomplete-dd .p-autocomplete-dropdown:disabled {
  opacity: 0.6;
}

.mb-0 {
  margin-bottom: 0px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: none;
}

.form-group .pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -3px;
  right: 15px;
  font-family: "primeicons" !important;
  color: #01147c;
}

.form-group .p-inputwrapper-focus {
  border: none;
  background: transparent;
}

.text-blue-address {
  font-family: "Space Grotesk", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 22px;
  margin-bottom: 0px;
  color: #01147c !important;
}

.p-autocomplete-dd .p-autocomplete-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.physical-address-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.same-address-text {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}

.register-main-div .form-check-input {
  appearance: auto !important;
  vertical-align: middle !important;
  position: relative;
  top: -10%;
}

/* .register-main-div .form-group.form-check.login-checkbox {
    margin-left: 9% !important;
} */
.p-calendar {
  width: 100%;
  height: 37px;
}

.document-main-div .documents-text {
  position: relative;
}

.document-main-div .upload-documents {
  position: absolute;
  top: 33px;
}

.form-group.form-check.register-checkbox {
  margin-left: 12% !important;
}

.p-component.dropdown-acc {
  width: 100% !important;
  position: relative;
  display: block;
}

.info-section-second {
  padding-right: 45px !important;
  padding-left: 75px !important;
}

.info-section .first-document-upload .pi::before {
  font-size: 16px;
  color: #01147c;
}

.info-section-border {
  border-right: 1px solid rgba(0, 0, 0, 0.13);
}

.upload-document {
  position: relative;
  /* top: 28%; */
}

.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  color: #01147c !important;
}

.document-main-div .css-i4bv87-MuiSvgIcon-root {
  font-size: 2rem;
}

.error-msg {
  color: red !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  line-height: 15px !important;
}

.acc-screen {
  padding-top: 20px;
}

.acc-screen .form-group .error-msg {
  position: absolute;
  left: 0;
  bottom: -15px;
}

.acc-screen .form-group .second-doc-error {
  bottom: -30px;
}

input::placeholder {
  color: rgb(159, 159, 159) !important;
  font-family: "IBM Plex Sans" !important;
}

.row.basic-info-div {
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.btn-continue .p-button-label {
  font-weight: 500 !important;
}

.bottom-btns {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

/* .p-button:enabled:hover {
  background: transparent;
} */
.ankertag {
  margin-top: -30px;
  margin-left: 61px;
}

/* @media (min-width: 1400px){
  .info-section-second{
    padding-right: 130px !important;
    padding-left:  130px !important;
  }
  .info-section {
    padding-right: 130x !important;
    padding-left: 130px !important;
  }
} */

.Imgupload-buttonbar {
  height: 150px;
  max-height: 150px;
}

.myfileupload-buttonbar {
  display: flex;
  align-items: flex-start;
}

/* .Imgupload-buttonbar.myfileupload-buttonbar {
  height: auto !important;
  align-items: flex-start;
} */

.Imgupload-buttonbar.myfileupload-buttonbar .col-md-8 {
  position: relative;
  display: flex;
}

.myfileupload-buttonbar input {
  position: absolute;
  top: 0;
  right: 0;
  /* left: -20%; */
  margin: 0;
  border: solid transparent;
  border-width: 0 0 100px 200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transform: translate(250px, -50px) scale(1);
  -moz-transform: translate(-300px, 0) scale(4);
  direction: ltr;
  cursor: pointer;
}

.msg {
  font-size: 20px !important;
  margin-bottom: -26px;
  margin-top: 11px;
}

.myui-button {
  /* position: relative; */
  cursor: pointer;
  text-align: center;
  overflow: visible;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 720px) {
  .info-section-second {
    padding-right: 75px !important;
    padding-left: 100px !important;
  }

  .info-section {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.p-dialog .p-dialog-content:last-of-type {
  border-radius: 6px;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
  overflow-y: hidden;
}

.myfileupload-buttonbar .p-button {
  height: 47px;
}

.mr-10 {
  margin-right: 10px;
}

.requiredBlue-img {
  height: 13px !important;
}

.dropdown-custome {
  display: flex;
}

.dropdown-custome input {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
}

.dropdown-custome button {
  border: none;
  background: none;
  position: absolute;
  right: 0;
}

.drop-down-close {
  position: absolute;
  width: 100%;
  display: none;
  background-color: #86b7fe;
  padding: 10px;
  font-size: 14px;
  color: #495057;
  z-index: 999;
  border-radius: 6px;
}

.drop-down-open {
  width: 100%;
  background-color: #86b7fe;
  display: block;
  padding: 10px;
  font-size: 14px;
  color: #495057;
  z-index: 999;
  border-radius: 6px;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0px 0px !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding-left: 10px !important;
  padding-top: 7px !important;
  line-height: 22px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
}

.spinner-class {
  position: fixed;
  left: 57.5%;
  top: 55.5%;
  transform: translate(-55.5%, -57.5%);
}

.spinner-class-security {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px !important;
}

.p-progress-spinner-circle {
  stroke: #01147c !important;
}

.acc-screen .form-group input {
  height: 32px;
  width: 100%;
  display: block;
  font-size: 13px !important;
  line-height: 16px;
  padding: 8px 10px;
  color: #000000;
}

.acc-screen .form-group input::placeholder {
  color: rgb(159, 159, 159) !important;
  font-size: 13px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect .p-multiselect-label {
  padding: 0px 20px;
  padding: 0px 12px;
}

.p-multiselect .p-multiselect-label.p-placeholder::placeholder {
  color: rgb(159, 159, 159);
}

.p-inputtext::placeholder {
  color: rgb(159, 159, 159);
}

.p-multiselect-trigger-icon.p-c.pi.pi-chevron-down:before {
  text-align: center;
  position: absolute;
  top: 3px;
  right: 13px !important;
  font-family: "primeicons" !important;
  color: #01147c;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect.p-component.dropdown-acc {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect-header {
  display: none;
}

.p-checkbox {
  display: none;
}

.dashboard {
  overflow: hidden;
}

.p-progress-spinner-svg {
  width: 45%;
  height: 45%;
}

.register-address {
  display: flex;
  align-items: center;
}

.register-address .form-check-label {
  margin-left: 10px;
  font-size: 14px;
  line-height: 18px;
}

.register-address input {
  width: 14px;
}

.button-section {
  padding-top: 50px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basicinfomain .button-section {
  padding-top: 10px;
  margin-bottom: 30px;
}

.second-btn {
  margin: 20px;
}

.bottom-btns {
  width: 80%;
  margin-right: 0px;
}

.myfileupload-buttonbar .document-upload {
  padding: 10px 10px;
  height: 32px;
  margin: 0px;
  width: 100px;
  justify-content: center;
  background: #f5f4f4;
  color: #01147c;
  text-align: center;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  font-weight: 500;
  margin-top: 2px;
}

.myfileupload-buttonbar .p-button.document-upload:hover {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: #01147c;
}

.myfileupload-buttonbar .p-button.document-upload .pi-spinner {
  line-height: normal;
  font-size: 11px;
  position: absolute;
  margin-right: 5px;
  left: 8px;
}

.uploadimg {
  max-width: 150px;
  max-height: 150px;
}

.payment-btn-dialog .pi-spinner {
  position: absolute;
  left: 5px;
  font-size: 12px;
}

.update-btn {
  width: 142px;
}

.delete-doc {
  width: auto;
  background: transparent;
  border: none;
  margin-left: 10px;
  /* margin-top: -10px; */
}

.doc-upload-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#fileupload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: flex-start;
}

.file-name {
  margin-right: 10px;
  width: 100px;
  display: block;
  overflow: hidden;
}

.pdf-btn {
  border: none;
  display: flex;
  position: relative;
  width: auto;
  min-width: 100px;
  max-width: 400px;
}

.pdf-btn p {
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}

/* Documents style */
.info-section .text-area {
  max-height: 200px;
  min-height: 150px;
  font-size: 14px;
  padding: 10px;
}

.info-section .textarea-wrap .text-area::placeholder {
  color: rgb(172, 172, 172) !important;

  font-size: 13px !important;
  line-height: 18px;
}

/* Tooltip text */
.delete-tooltip {
  visibility: hidden;
  width: auto;
  background-color: rgb(244, 244, 244);
  text-align: center;
  padding: 5px 9px;
  font-size: 12px;
  right: -120px;
  top: -10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.doc-tooltip {
  visibility: hidden;
  width: auto;
  background-color: rgb(244, 244, 244);
  text-align: center;
  padding: 5px 9px;
  font-size: 12px;
  right: -130px;
  top: -10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.delete-tooltip::after {
  content: " ";
  position: absolute;
  top: 50%;
  /* At the bottom of the tooltip */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(244, 244, 244) transparent transparent transparent;
}

.doc-tooltip::after {
  content: " ";
  position: absolute;
  top: 50%;
  /* At the bottom of the tooltip */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(244, 244, 244) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.delete-doc:hover .delete-tooltip {
  visibility: visible;
}

.pdf-btn:hover .doc-tooltip {
  visibility: visible;
}

.document-main-div .info-section .file-name {
  max-width: 150px;
  min-width: 120px;
  color: #01147c;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Curency-section style */

.recevie-currency {
  width: 100%;
  padding: 0px 55px;
  margin: 0 auto;
  padding-top: 10px;
}

.recevie-currency h3 {
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 25px;
}

.currency-details thead tr:first-child {
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}

.currency-details table th {
  font-size: 14px;
  color: #01147c;
  line-height: 19px;
  padding: 10px 15px;
  font-weight: 600;
  padding-left: 50px;
}

.currency-details table td {
  font-size: 13px;
  font-family: "IBM Plex Sans" !important;
  padding-left: 50px;
}

.currency-details table .currenecy-check {
  width: 17px;
  height: 17px;
  margin-left: 20px;
}

.text-left {
  text-align: left;
}

.status-check {
  vertical-align: middle;
}

/* toggle switch style */

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #01147c;
  width: 40px;
  height: 18px;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch .p-inputswitch-slider {
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 30px;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch .p-inputswitch-slider:before {
  background: #01147c;
  width: 12px;
  height: 12.5px;
  left: 1px;
  top: 11.5px;
  border-radius: 50%;
  display: flex;
  transition-duration: 0.2s;
}

.switch-style .p-inputswitch-checked .p-inputswitch-slider:before {
  width: 14px;
  height: 14px;
  left: 4px;
  top: 11px;
  background-color: #ffff;
}

.switch-style .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #01147c;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: transparent;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-focus .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  border: none;
  outline: none;
  box-shadow: none;
}

.p-inputswitch {
  width: 37px;
  height: 17px;
}

/* **************paymentsetting css***************** */
.btn.btn-payment {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 1px;
  padding: 5px 35px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.btn.btn-payment-selected {
  background: #01147c;
  color: #fcfcfc;
  border-right: 1px solid #ffff;
  border-left: 1px solid #ffff;
  border-radius: 0px;
  padding: 5px 35px;
  font-weight: 500;
  font-size: 14px;
}

.btn.btn-payment:hover {
  background: #01147c;
  color: #fcfcfc;
  border: 1px solid #01147c;
  padding: 5px 35px;
  font-weight: 500;
  font-size: 14px;
}

.type-of-payment {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn.btn-payment:disabled {
  opacity: 0.6;
}

.payment-screen .form-check-input {
  background: transparent;
  border: 1px solid #01147c;
}

.check-display {
  display: flex;
}

.yes-check {
  margin-right: 45px;
}

.acc-screen.payment-screen {
  padding-top: 30px;
}

.payment-screen .payment-section {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 35px;
}

.payment-screen h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 8px;
}

.payment-screen .form-check-input:checked[type="radio"] {
  background-image: url(../images/checkeclipse.svg);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: 10px;
  cursor: pointer;
}

.payment-screen .form-check-label {
  font-size: 16px;
}

/* ***************Fees css************* */
.fees-tab {
  width: 90%;
  margin: 0 auto;
  padding: 0px 0px;
  padding-top: 10px;
}

.fees-tab h3 {
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 25px;
}

.fees-tab table th {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #01147c;
  padding-left: 35px;
  text-align: center;
}

.fees-tab table tr td {
  padding-left: 35px;
}

.fees-tab table th:last-child {
  border: none;
}

.currency-img {
  height: 24px;
}

.currency-ind-img {
  height: 35px;
  padding-top: 3px;
}

.fees-screen {
  padding: 10px 0px;
}

.fees-screen .form-group input {
  text-align: right;
  font-weight: 500;
  width: 110px;
  font-size: 15px !important;
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  height: 30px;
  margin-right: 10px;
}

.fees-screen table td {
  font-size: 16px;
  padding: 10px 0px;
  min-width: 135px;
  width: auto;
  display: inline-block;
  font-weight: 400;
  border: none;
}

.fees-screen table .fees-table-row .country-flag {
  min-width: 80px;
  font-size: 12px;
}

.fees-screen table td .form-group {
  margin: 0px;
}

.fees-screen table th {
  font-size: 12px;
  line-height: 15px;
  min-width: 130px;
  display: inline-block;
  font-weight: 500;
  padding: 10px 10px 10px 0px;
  border: none;
}

.fees-screen table tr {
  border-bottom: 1px solid #dee2e6;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: #adb5bd;
  color: #01147c;
  background: none;
}

.payment-button-section {
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-payment-back {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 66px;
  font-weight: 500;
  font-size: 15px;
}

.btn-payment-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
}

.fees-tab thead tr:first-child {
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}

.fees-screen table .country-flag {
  font-size: 13px;
  padding: 10px 0px;
  min-width: 80px;
  vertical-align: middle;
}

.country-flag img {
  width: 20px;
  margin-right: 7px;
  vertical-align: middle;
}

.fees-tab .form-group {
  margin-bottom: 0px;
  padding: 0px 15px;
}

.fees-tab .form-group input {
  height: 30px;
  padding: 5px 10px;
}

/* Phone input */

.PhoneInputCountryIcon--border {
  border: none;
  padding: 0px;
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputInput {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountryIconImg {
  width: 80%;
  margin-top: 1px;
  height: 80%;
}

.PhoneInput {
  padding: 0px 10px;
  height: auto;
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
  box-shadow: none;
  border: none;
}

.Phone-input {
  padding: 0px;
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
  color: #000000 !important;
  opacity: 0.5 !important;
}

.PhoneInputInput:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.PhoneInputCountry {
  padding: 7px 10px;
  border-radius: 6px 0px 0px 6px;
  margin-right: 0px;
  background-color: #e9e9e9;
  border-right: 1px solid gainsboro;
}

.PhoneInputCountrySelect {
  display: block;
  width: 100%;
  font-family: "IBM Plex Sans" !important;
  font-size: 14px;
  padding: 10px 5px;
  line-height: 17px;
  background-color: transparent;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
  display: none;
}

.PhoneInputCountrySelect option {
  padding: 10px;
  margin: 5px 0px;
}

.dropdown-phn {
  background-color: #01147c;
}

.personal-details .form-group .error-msg {
  padding-left: 14px;
}

.form-group .p-calendar .pi-calendar {
  line-height: normal;
  font-family: "primeicons";
}

.form-group .p-calendar .p-button {
  background-color: #01147c;
  border: none;
  height: 32px;
  position: relative;
}

.form-group .p-calendar .p-button:hover {
  background-color: #01147c;
}

.form-group .p-calendar .p-button:enabled:active {
  background: #01147c;
  color: #ffffff;
  border-color: none;
}

.form-group .p-calendar .p-button:active {
  background-color: #01147c;
}

.form-group .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.form-group .p-calendar .p-inputtext:enabled:hover {
  border: 1px solid #ced4da !important;
}

.form-group .p-inputtext:enabled:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.pi-calendar:before {
  font-size: 12px;
}

.form-group .p-button:enabled:active {
  background: transparent;
  color: #ffffff;
  border-color: none;
}

.p-button:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #ffffff;
  border-color: none;
}

/* POPUP STYLE */

.popup-screen {
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.popup-screen .p-dialog-content {
  width: 450px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.popup-img {
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
}

.popup-screen .p-dialog-content .awesome-text {
  font-weight: 600 !important;
  font-size: 22px !important;
  color: #01147c !important;
  line-height: 32px !important;
  padding-bottom: 20px;
  border-bottom: 2px solid #01147c;
  margin-bottom: 15px;
  vertical-align: middle !important;
}

.awesome-text h2 {
  font-weight: 600;
  line-height: 32px;
  font-family: "Space Grotesk", sans-serif;
  margin-bottom: 20px;
}

.Payment-screen .awesome-text h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #01147c;
}

.popup-screen .p-dialog-content .incredible-text {
  font-family: "Space Grotesk", sans-serif !important;
  font-weight: 700 !important;
  font-size: 27px !important;
  color: #01147c !important;
  line-height: 32px !important;
  margin-bottom: 10px;
  vertical-align: middle !important;
}

.incredible-text h2 {
  font-weight: 600;
  line-height: 32px;
  font-family: "Space Grotesk", sans-serif !important;
  margin-bottom: 20px;
}

.popup-screen .text-center {
  margin-bottom: 20px;
}

.popup-screen .text-center p {
  font-size: 18px;
  line-height: 23px;
}

/* User-management-popup */
.user-dialog {
  width: 50%;
  display: block;
  max-height: auto;
  min-height: auto;
}

.user-dialog .p-dialog-content {
  padding: 30px 30px 0px 30px;
  width: 100%;
}

.user-dialog .p-dialog-content h2 {
  font-size: 20px;
  line-height: 23px;
  font-family: "IBM Plex Sans";
  margin-bottom: 20px;
  text-align: center;
}

.user-dialog .user-row {
  margin: 0px -10px;
}

.user-dialog .form-control {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 20px;
}

.user-dialog .p-autocomplete-dd .p-autocomplete-input {
  padding: 5px 10px;
  height: 32px;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
}

.user-dialog .form-control::placeholder {
  font-size: 13px;
  color: rgb(159, 159, 159);
}

.user-dialog .payment-btn-dialog {
  text-align: right;
}

.close-btn {
  border: none;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 15px;
}

.close-btn img {
  width: 13px;
  height: 13px;
}

#confirm-popup {
  display: block;
  background-color: #fff;
  width: 400px;
  border-radius: 6px;
}

#confirm-popup .p-dialog-content {
  border-radius: 6px 6px 0px 0px;
  padding-top: 20px;
  justify-content: center;
  align-items: flex-start;
}

#confirm-popup .p-dialog-content span {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #01147c;
  margin-left: 0px;
}

#confirm-popup .p-dialog-content .p-confirm-dialog-icon {
  margin-right: 5px;
  font-size: 16px;
}

.p-dialog .p-dialog-footer button {
  margin: 0px;
}

#confirm-popup .p-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
}

#confirm-popup .p-dialog-footer .p-button {
  border: 1px solid #01147c;
  padding: 9px 30px;
}

#confirm-popup .p-dialog-footer .p-confirm-dialog-reject {
  color: #01147c;
  border: 1px solid #01147c;
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}

#confirm-popup .p-dialog-footer .p-confirm-dialog-accept {
  color: #fff;
  background-color: #01147c;
  border: 1px solid #01147c;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}

/* ********************************** */

.paymentsetting-readscreen.acccount-seeting-screen .read-only {
  width: 200px;
  display: block;
  padding: 8px 0px;
}

.paymentsetting-readscreen.acccount-seeting-screen .read-text {
  width: 100%;
  display: table-cell;
  padding: 8px 0px;
  text-align: left;
  padding-left: 40px;
}

.paymentsetting-readscreen .read-only span {
  border: 1px solid #212a2f;
  border-radius: 26px;
  display: block;
  font-size: 14px;
  padding: 9px 10px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #01147c;
  margin-left: 0px;
}

.paymentsetting-readscreen .read-text {
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;

  color: #000000;
}

.read-only-heading {
  font-size: 22px;
  line-height: 25px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
}

.acccount-seeting-screen table td .form-check-label {
  font-size: 13px;
  font-weight: 500;
  color: #212a2f;
}

.acccount-seeting-screen table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #01147c;
  padding: 10px 0px 10px 0px;
  display: inline-block;
  width: 22%;
  border: none;
  text-align: center;
}

.namefield-border {
  border: 1px solid #212a2f;
  border-radius: 26px;
  padding: 8px 7px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 15px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.namefield-border.delivery-type {
  max-width: 300px;
}

.form-check .namefield-border .form-check-input {
  margin: 0px;
  margin-right: 10px;
  border-radius: 50px;
  border-color: #01147c;
  cursor: pointer;
}

.form-check .namefield-border .form-check-input:checked {
  background-color: #01147c;
  border-color: #01147c;
  cursor: pointer;
}

.form-check .namefield-border .form-check-input:checked[type="checkbox"] {
  background-image: url(../images/icon/checked-icon.png);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.acccount-seeting-screen .form-check-input:checked[type="radio"] {
  background-image: url(../images/white-tick-check.svg);
  background-repeat: no-repeat;
  background-color: #01147c;
  border: none;
}

.acccount-seeting-screen .form-check-input:checked[type="radio"] .form-check-label :active {
  color: red;
}

.acccount-seeting-screen .form-check-input[type="radio"] {
  margin-top: 6px;
  border: 1px solid #212a2f;
}

.form-check-input:focus {
  box-shadow: none;
}

.acccount-seeting-screen h2 {
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 25px;
}

.acccount-seeting-screen td {
  border: none;
  display: inline-block;
  width: 20%;
  padding: 0px;
  margin-right: 30px;
}

.acccount-seeting-screen thead tr:first-child {
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}

.acccount-seeting-screen .user-tab {
  width: 90%;
  margin: 0 auto;
}

.user-tab .table {
  text-align: center;
}

.wallet-empty-message {
  display: flex;
  justify-content: center;
  text-align: center;
  color: orange;
}

.user-tab {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
}

.user-tab .editbtn {
  background: none;
  border: none;
  padding: 0px;
}

/* .user-tab .deletebutton{
  position: relative;
  top: -5px;
} */
.user-tab .editbtn:hover {
  background: none;
  border: none;
}

.user-tab .editbtn .pi-pencil {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}

.pi-pencil:before {
  content: "\e942";
  color: #000;
  font-size: 12px;
}

.pi-sort-alt:before {
  content: "\e99e";
  color: #01147c;
  font-size: 13px;
}

.pi-sort-amount-up-alt:before {
  content: "\e9a2";
  color: #01147c;
  font-size: 13px;
}

.pi-sort-amount-down-alt:before {
  content: "\e9a2";
  color: #01147c;
  font-size: 13px;
}

.user-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
}

.user-heading h2 {
  font-size: 17px;
  line-height: 20px;
}

.user-manage .btn-back:hover {
  background-color: transparent;
  color: #01147c;
}

.send-btn {
  text-align: right;
}

.user-heading .second-btn {
  margin: 5px;
}

.send-btn .btn {
  font-size: 12px;
  line-height: 15px;
}

.send-btn .btn:hover {
  font-size: 12px;
  line-height: 15px;
  background-color: transparent;
  color: #01147c;
}

.datatable-doc-demo table {
  border: none;
  overflow: scroll;
}

.datatable-doc-demo .p-datatable .p-datatable-thead>tr>th {
  font-size: 14px;
  color: #01147c;
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
  line-height: 19px;
  padding: 10px 15px;

  font-family: "IBM Plex Sans" !important;
  background: transparent;
  font-weight: 600;
  padding-left: 10px;
}

.datatable-doc-demo,
.payment-info {
  position: relative;
  display: block;
  width: 100%;
}

.payment-info .loading-icon {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  min-height: 300px;
}

.datatable-doc-demo .p-progress-spinner,
.payment-info .p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
}

.datatable-doc-demo .p-progress-spinner-svg,
.payment-info .p-progress-spinner-svg {
  width: 35%;
  height: 35%;
}

.datatable-doc-demo .loading-icon {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -20%);
  min-height: 500px;
}

.transaction-screen .datatable-doc-demo .p-datatable .p-datatable-thead>tr>th {
  font-size: 10px;
  color: #01147c;
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
  line-height: 19px;
  padding: 10px 2px;
  font-family: "IBM Plex Sans" !important;
  background-color: white;
  font-weight: 600;
  padding-left: 5px;
}

.transaction-details-screen {
  width: 95%;
  margin: 0 auto;
}

.transaction-screen .datatable-doc-demo .p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  word-break: break-all;
  padding: 8px 2px;
  font-size: 10px;
  font-family: "IBM Plex Sans" !important;
  background: transparent;
  padding-left: 5px;
  border-bottom: none;
}

.transaction-screen .datatable-doc-demo .p-datatable .p-datatable-tbody>tr .message-id {
  min-width: 100px;
  max-width: 200px;
}

.transaction-screen .datatable-doc-demo .p-datatable .p-datatable-tbody>tr .small-col {
  min-width: 90px;
  max-width: 90px;
}

.datatable-doc-demo .p-datatable .p-datatable-thead>tr>th:hover {
  background: transparent;
}

.p-datatable .p-datatable-tbody>tr {
  background: transparent;
  color: black;
  transition: none;
}

.datatable-doc-demo .p-datatable-wrapper {
  margin-bottom: 50px;
}

.datatable-doc-demo .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: transparent;
}

.datatable-doc-demo .p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: none;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: transparent;
  color: black;
}

.datatable-doc-demo .p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  padding: 7px 15px;
  font-size: 14px;
  font-family: "IBM Plex Sans" !important;
  background: transparent;
  padding-left: 10px;
}

.datatable-doc-demo .p-paginator {
  background: transparent;
  color: #01147c;
  border: solid #e9ecef;
  border-width: 0;
  justify-content: flex-end;
  padding: 10px 0px;
  border-radius: 6px;
}

.datatable-doc-demo .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  cursor: pointer !important;
  min-width: 10px;
  height: 10px;
}

.datatable-doc-demo .pi-angle-left:before,
.pi-angle-right:before,
.pi-angle-double-right:before,
.pi-angle-double-left:before {
  color: #01147c;
  opacity: 5;
  cursor: pointer;
}

.datatable-doc-demo .p-paginator-current {
  color: #01147c;
  font-size: 14px;
}

.datatable-doc-demo .p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #01147c;
  min-width: 30px;
  height: 30px;
  transition: none;
  background: #c1cef8;
  border-radius: 50%;
}

.datatable-doc-demo .p-inputwrapper-filled {
  display: none;
}

.datatable-doc-demo {
  display: block;
  width: 100%;
}

.datatable-doc-demo .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: transparent;
  color: none;
}

.datatable-doc-demo .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-datatable-emptymessage):nth-child(odd) {
  background: transparent;
  color: black;
}

.p-autocomplete-dd .p-autocomplete-input {
  font-family: "IBM Plex Sans" !important;
}

.document-main-div .text-area-row {
  margin-bottom: 50px;
}

.fees-screen .tabview-demo .p-tabview .p-tabview-nav {
  padding: 0px 50px;
}

.document-main-div .text-area-row .col-6 {
  position: relative;
}

.document-main-div .text-area-row .error-msg {
  position: absolute;
}

.tool-tip-info {
  margin-left: 5px;
}

.p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: #01147c;
  border-color: #2e4294;
  color: #01147c;
}

.custom-target-icon {
  display: none;
  flex-direction: column;
  background-color: rgb(244, 244, 244);
  position: absolute;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: 12px;
  top: 0;
  margin-left: 15px;
  width: auto;
  max-width: 80%;
  z-index: 999;
}

.input-label {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 14px;
  line-height: 27px;
}

.tool-tip-info:hover .custom-target-icon {
  display: block;
  cursor: pointer;
}

.custom-target-icon h4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
}

.custom-target-icon ul {
  margin-top: 7px;
}

.custom-target-icon ul li {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 17px;
  list-style-type: disc;
  margin-bottom: 5px;
}

/* 
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #ffffff;
  border-color: #adb5bd;
  color: #adb5bd;
} */

element.style {
  width: 131px;
  left: 111.344px;
}

.custom-target-icon::after {
  content: " ";
  position: absolute;
  top: 12px;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgb(244, 244, 244) transparent transparent;
}

.profile-save-btn {
  display: flex;
  justify-content: end;
  padding-top: 80px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #01147c;
  color: #01147c;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #01147c;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #01147c;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tabview .p-tabview-nav li.p-unselectable-text .p-tabview-nav-link {
  border-bottom: 1px solid #dee2e6;
}

.p-tabview .p-tabview-nav li.p-unselectable-text .p-tabview-nav-lin:hover {
  border-bottom: 1px solid #01147c;
  background: none;
  color: #01147c;
}

.profile-main-div .acc-screen {
  padding: 20px 0px 0px 0px;
  position: initial;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile-main-div .account-details {
  padding: 20px 0px 0px 0px;
  position: initial;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}

.account-details .form-control::placeholder {
  font-size: 16px;
  line-height: 20px;
}

.profile-main-div .facilitator-screen {
  width: 90%;
  padding-top: 30px;
}

.profile-main-div .button-section {
  padding-top: 0px;
}

.profile-content {
  width: 95%;
  padding-top: 25px;
}
.profile-content .acc-screen{
  padding: 10px 0px;
}
.profile-content .acc-screen .row{
  margin: 0px -10px;
}
.profile-main-div .p-tabview .p-tabview-panels {
  background: transparent;
}

.errorpage-main h1 {
  font-weight: 600;
  font-size: 50px;
  color: #01147c !important;
}

.errorpage-main h2 {
  font-size: 18px;
  color: black !important;
}

.errorpage-main .forgot-password a {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: normal;
  text-decoration: underline !important;
}

.errorpage-main .errorLogin-form {
  background: white;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px;
  border-radius: 10px;
  position: relative;
  padding-top: 35px;
  padding-left: 50px;
  padding-right: 50px;
  width: 455px;
  color: #01147c;
  opacity: 127%;
  padding-bottom: 10px;
}

.errormsglogo {
  position: relative;
  bottom: 29px;
  height: 40px;
}

.errorpage-main {
  text-align: center !important;
}

.errorpage {
  align-items: center;
  flex: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.password-input-icon {
  position: relative;
}

.textarea-wrap {
  position: relative;
}

.facilitator-main .editbtn {
  background: none;
  border: none;
  width: 30px;
  margin-right: 10px;
  padding: 0px;
}

.facilitator-main .editbtn:hover {
  background: none;
  border: none;
}

.facilitator-main .editbtn .pi-pencil {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}

.facilitator-main .editbtn .pi-trash {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}

.facilitator-main .editbtn .pi-trash::before {
  font-size: 14px;
}

.facilitator-main .spinner-class {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  height: 50px !important;
}

.pi-trash:before {
  content: "\e93d";
  color: #000;
  font-size: 14px;
}

.p-tabview-selected .p-tabview-nav-link {
  background-color: #01147c;
}

.basicinfo-main .button-section {
  padding-top: 15px;
  margin-left: -40px;
}

.basicinfo-main {
  padding: 30px 0px 150px 0px;
}

.currency-screen .payment-button-section {
  padding-top: 100px;
}

.paymentsetting-readscreen .form-check {
  padding: 0px;
  margin: 0px;
}

.paymentsetting-readscreen .form-check.yes-check {
  margin: 0px;
}

.regiter-popup {
  width: 25%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.popup-body {
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  /* Black w/ opacity */
}

.Payment-screen .awesome-text p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

.Payment-screen .awesome-text {
  margin-bottom: 20px;
}

.payment-screen-btn {
  display: flex;
  width: 300px;
  margin: 0 auto;
}

.form-control:disabled {
  background-color: #ffff;
  opacity: 0.6;
}

.form-control .PhoneInputInput:disabled {
  background-color: #ffff;
  opacity: 0.6;
}

.form-control[readonly] {
  background-color: #ffff;
  opacity: 0.6;
  color: rgb(0, 0, 0);
}

.FFpopup-btn {
  display: flex;
  align-items: center;
  justify-content: right !important;
}
.FFpopup-btn .btn-continue{
  margin: 20px 0px 20px 10px;
}
.F-Fee-main .button-section {
  justify-content: end;
}

.facilitator-screen .facilitator-tabs {
  padding: 10px 0px;
}

.facilitator-screen .facilitator-tabs .btn.btn-continue {
  font-size: 12px;
}

.btn.btn-continue .pi-plus {
  font-size: 12px;
}

.AddFacilitator-div .button-section {
  justify-content: end;
}

.acc-screen .form-group .error-msg-upload {
  left: 0;
  position: absolute;
  bottom: -16px;
}
.Imgupload-buttonbar.myfileupload-buttonbar .col-md-6 {
  position: absolute;
  display: flex;
}
.acc-screen .form-group .errormsg-limit {
  left: 0;
  position: absolute;
  bottom: -30px;
}

.hr {
  color: rgba(0, 0, 0, 0.08);
}

/* .developer-resorce-Screen {
  padding: 15px 0px 80px 0px !important;
} */
.developer-resorce-Screen .card {
  background: #fbfbfb;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-bottom: 20px;
  margin-left: 10px;
  height: 50%;
  max-height: 390px;
  margin-right: 10px;
  border: none;
}

.developer-resorce-Screen h1 {
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
  font-size: 17px;
}

.developer-resorce-Screen h2 {
  /* font-weight: 600;
  font-size: 23px;
  text-align: center;
  margin-bottom: 5px;
  color: #01147c; */
  font-family: "Space Grotesk", sans-serif !important;
  text-align: center;
  font-weight: 700;
  font-size: 18x;
  line-height: 23px;
  color: #01147c !important;
}

/* ****CheckQuotes css */
.createpayment-screen .col-md-6 .form-group.payment-quotes {
  width: 100%;
}

.createpayment-screen .form-check-input {
  background: transparent;
  border: 1px solid #01147c;
  height: 16px !important;
  width: 16px !important;
  padding: 8px !important;
}

.createpayment-screen .form-check-input:checked[type="radio"] {
  background-image: url(../images/checkeclipse.svg);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: 10px;
}

.payment-screen .form-check-label {
  font-size: 16px;
}

.createpayment-screen .form-check {
  display: flex;
}

.createpayment-screen .form-check-label {
  width: 120px;
  text-align: left;
}

.createpayment-screen .form-group .form-check-label {
  font-size: 14px;
  line-height: 25px !important;
  margin-left: 5px;
}

.border-bottom-checkQuoet {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 30px;
}

.check-quets-visibility-none {
  visibility: hidden !important;
}

.incredible-img {
  height: 55px;
}

.file-name-delete {
  margin-top: 9px;

  display: flex;
  position: relative;
}

.createpayment-screen .button-section {
  /* padding-top: 45px; */
  padding-bottom: 10px;
}

.createpayment-screen.createpaymnetbtn .button-section {
  padding-bottom: 100px;
}

.btn-next {
  width: 100px;
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 8px 20px;
  font-weight: 400 !important;
  font-size: 14px;
}

.btn.btn-next:hover {
  background: #01147c;
  color: white;
  border: 1px solid #01147c;
}

.fees-error {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 90%;
}

.fees-error .col-error {
  display: flex;
  flex: 50%;
  justify-content: center;
  padding-top: 10px;
}

.fees-table {
  position: relative;
}

.p-tabview .p-tabview-panels {
  padding: 0px;
}

.doc-img {
  width: 20px;
}

.user-dialog .form-group .error-msg {
  position: absolute;
}

.info-section {
  padding-left: 45px !important;
  padding-right: 75px !important;
}

.acc-screen .btn-continue .p-button-loading-icon,
.acc-screen .btn-back .p-button-loading-icon {
  position: absolute;
  left: 7px;
  font-size: 13px;
}

.getquote-btn {
  display: flex;
  justify-content: end;
}

.btn.btn-getquote {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: white;
  padding: 6px 22px;
}

.btn.btn-getquote:hover {
  background: #01147c;
}

.chechquotes-screen .btn.btn-continue {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 8px 30px;
}

.send-exact-border {
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 13px !important;
  line-height: 25px !important;
  padding-left: 10px;
  padding-top: 3px;
}

.chechquotes-screen .form-check-label {
  margin-left: 5px;
}

.hdfc-logo-img-text {
  display: flex;
  color: #01147c;
}

.bankImg-size {
  height: 25px;
  width: auto;
}

.rtp-img-text {
  display: flex;
  justify-content: right;
}

.ml {
  margin-left: 5px;
}

.text-color-checkquoet {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.text-color-checkquoet .col {
  flex-wrap: nowrap;
}

.chechquotes-screen .card {
  position: relative;
  padding-bottom: 70px;
}

.text-color-checkquoet p {
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.text-color-checkquoet h4 {
  font-size: 16px;
  line-height: 29px;
  font-weight: 600;
  color: rgb(66, 110, 213);
}

.text-color-checkquoet h4 span {
  font-size: 14px;
  line-height: 16px;
  margin-left: 2px;
  color: rgb(66, 110, 213);
}

.text-color-checkquoet p span {
  font-size: 11px;
  margin-left: 2px;
  color: #495057;
}

.procced-btn {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  left: 0;
  text-align: center;
}

.text-color-checkquoet .recipient-amt {
  font-size: 15px;
  font-weight: 600;
  color: rgb(66, 110, 213);
}

.btn.btn-procced {
  background: #fcfcfc;
  border: 1px solid #01147c;
  color: #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  padding: 4px 20px;
  font-weight: 500;
  font-size: 14px;
}

.btn.btn-procced:hover {
  background: #01147c;
  border: 1px solid #01147c;
  color: #ffffff;
}

.checkquote-cards {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding-top: 20px;
}

.chechquotes-screen .card {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 12px;
  flex: 0 0 31.33%;
  margin: 15px 10px;
  border: none;
}

.chechquotes-screen .card .card-body {
  padding: 20px 20px 0px 20px;
}

.chechquotes-screen .card .form-group {
  margin-bottom: 0px;
  padding: 0px;
  display: flex;
  align-items: flex-start;
}

.text-color-TargetAmount {
  font-weight: 600;
  font-size: 18px;
}

.procced-btn-section {
  display: flex;
  justify-content: end;
}

.amount-left {
  display: flex;
  justify-content: flex-end;
}

.hdfc-text {
  margin-left: 5px;
  position: relative;
  top: -3px;
}

.checkquote-cards .card-first-row {
  margin-bottom: 10px;
}

.checkquote-cards .Recipient-amt {
  margin-bottom: 10px;
}

.upload-img-section {
  display: flex;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: none;
  border: #adb5bd;
}

.fees-table .border-right.country-flag {
  font-size: 12px;
  line-height: 32px;
  min-width: 80px;
}

.developer-save-btn {
  margin-right: 20px;
  padding: 8px 30px !important;
}

.developer-header-color {
  font-size: 15px;
  text-align: center;
}

.yes-btn-popup {
  padding: 8px 36px !important;
}

.developer-resorce-Screen p {
  font-size: 13px;
  line-height: 17px;
}

.realtime-payment-inputbox {
  background: #01147c;
  border: 1px solid #01147c;
  color: white;
  height: 35px;
  text-align: center;
  width: 100%;
}

.realtime-payment-inputbox:disabled {
  opacity: 0.6;
}

.partnership-drop .dropdown-acc input {
  height: 31px;
  padding: 7px 10px;
  color: #000;
  font-size: 14px;
  width: 75%;
  border: 1px solid rgba(1, 20, 124, 0.52) !important;
  border-radius: 1px !important;
  font-weight: 500;
}

.partnership-drop .pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -2px;
  right: 6px;
  font-family: "primeicons" !important;
  color: #01147c;
}

.partnership-drop .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: none;
}

.inputbox-partnership {
  border: 1px solid rgba(1, 20, 124, 0.52);
  height: 31px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.inputbox-partnership:disabled {
  opacity: 0.6;
}

.inputbox-partnership:focus-visible {
  outline: none;
}

.input-with-icon-bank {
  background-image: url("../images/banksvg.png");
  background-repeat: no-repeat;
  background-position: left 24% center;
  background-size: 15px;
  font-weight: 500;
  font-size: 14px;
}

.input-with-icon-realtime {
  background-image: url("../images/relatimepayment.svg");
  background-repeat: no-repeat;
  background-position: left 37% center;
  font-weight: 500;
  font-size: 14px;
}

.input-with-icon-mobilewallet {
  background-image: url("../images/mobilesvg.svg");
  background-repeat: no-repeat;
  background-position: left 25% center;
  background-size: 12px;
  font-weight: 500;
  font-size: 14px;
}

.input-with-icon-cashpickup {
  background-image: url("../images/cashpickupimg.svg");
  background-repeat: no-repeat;
  background-position: left 26% center;
  font-weight: 500;
  font-size: 14px;
}

.p-datepicker table td {
  padding: 0.2rem 0.5rem;
}

.p-datepicker table th {
  padding: 0.2rem 1rem !important;
}

/* Fees-screen css */

.Fees-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 150px;
}

.fees-discription {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 20px;
  letter-spacing: normal;
}

.Fees-heading h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: #000;
  letter-spacing: normal;
  margin-bottom: 20px;
  background: #e0e0e0;
}

.Fees-sending-payment h4 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: normal;
  color: #000;
}

.Fees-details-card-2 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 64%;
  margin: 0 auto;
}

.Fees-details-card-1 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 64%;
  margin: 0 auto;
}

.Fees-details-card-3 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 69%;
  margin: 0 auto;
}

.Fees-details-card-4 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 81%;
  margin: 0 auto;
}

.error-card-3 {
  justify-content: flex-end;
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-top: 10px;
}

.inner-card-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.Fees-details-card .fees-card {
  background-color: #fff;
  margin: 0px 5px;
  flex-direction: column;
  display: flex;
  padding: 10px 20px 15px 20px;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fees-card.country {
  flex: 10%;
  max-width: 160px;
}

.fees-card.currency {
  flex: 10%;
  max-width: 160px;
}

.fees-card.fixed-fee {
  flex: 15%;
  max-width: 280px;
}

.Fees-details-card-2 .error-message-col {
  width: 27.5%;
}

.Fees-details-card-1 .error-message-col {
  width: 30.5%;
}

.Fees-details-card-3 .error-message-col {
  width: 26.5%;
}

.Fees-details-card-4 .error-message-col {
  width: 23.5%;
}

.Fees-details-card .fees-card p {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 7px;
  color: #000;
  font-weight: 500;
}

.Fees-details-card .fees-card h3 {
  font-size: 16px;
  line-height: 22px;
  color: #01147c;
  font-weight: 700;
}

.toggle-country {
  background-color: transparent;
  display: flex;
  flex: 0 0 10%;
  background-color: transparent;
  margin: 0px 0px;
  padding: 10px 10px;
  min-width: 140px;
  border-radius: 4px;
}

.Toggle-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Toggle-btn .toggle-btn.active {
  border: none;
  color: #fff;
}

.Toggle-btn .toggle-btn {
  font-size: 16px;
  font-weight: 600;
  height: 35px;
  background-color: #fff;
}

.fees-card input {
  width: 100%;
  font-size: 14px;
  padding: 5px 27px 5px 5px;
  font-weight: 600;
  height: 27px;
  border: 1px solid black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
}

.fees-card input:focus {
  outline: none;
  border: 1px solid black;
}

.fees-card input::placeholder {
  text-align: right;
  color: rgb(159, 159, 159);
}

.switch-field {
  display: flex;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #ffffff;
  color: #01147c;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 12px 14px;

  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked+label {
  background-color: #01147c;
  color: #fff;
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 12px 14px;
}

.address-heading {
  margin-bottom: 10px;
}

.right-tab-section .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 100px) !important;
  overflow-x: hidden !important;
}

.right-tab-section .contain-scroll>div {
  min-height: auto !important;
  max-height: calc(100vh - 100px) !important;
  overflow-x: hidden !important;
  margin-bottom: 0px !important;
}

.right-tab-section .contain-scroll>div>div {
  overflow: hidden !important;
  background-color: transparent;
  padding-bottom: 55px;
}

.right-tab-section.transaction-tab .contain-scroll>div>div {
  overflow: hidden !important;
  background-color: transparent;
  padding-bottom: 25px;
}

.developer-resorce-Screen .right-tab-section .contain-scroll>div>div {
  padding-bottom: 120px;
}

.right-tab-section.transaction-tab .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 45px) !important;
  overflow-x: hidden !important;
}

.right-tab-section.transaction-tab .contain-scroll>div {
  min-height: auto !important;
  max-height: calc(100vh - 45px) !important;
  overflow-x: hidden !important;
}

.creatpayment-scroll {
  height: auto;
  min-height: auto !important;
  max-height: calc(100vh - 40px) !important;
  overflow-x: hidden !important;
}

.creatpayment-scroll>div {
  min-height: auto !important;
  max-height: calc(100vh - 65px) !important;
  overflow-x: hidden !important;
  margin-bottom: 0px !important;
}

.personal-details .button-section {
  padding-top: 23px;
  margin-bottom: 15px;
}

.payment-button-section {
  padding-top: 11px;
  margin-bottom: 10px;
}

/* Transaction Screen Style */
.transaction-screen {
  width: 95%;
  margin: 0 auto;
}

.transactionDetails-table td {
  font-size: 14px;
}

.transaction-link {
  cursor: pointer;
}

.payment-id-link:hover {
  color: #2e52e0;
  cursor: pointer;
}

.payment-id-link {
  color: #2e52e0;
  cursor: pointer;
}

.transaction-details {
  display: flex;
  flex-direction: column;
  flex: 100%;
  background: rgba(1, 20, 124, 0.05);
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 40px;
  text-align: left;
}

.transaction-details .d-flex {
  flex-wrap: wrap;
}

.transaction-details h2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #01147c;
}

.transaction-details .detials-col {
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  margin-bottom: 15px;
  padding-top: 10px;
}

.p-datatable-tbody {
  border-bottom: 1px solid #dee2e6;
}

.transaction-details .detials-col h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
}

.transaction-details .detials-col p {
  font-size: 12px;
  font-weight: 500;
  color: #495057;
  line-height: 20px;
}

.show-more button {
  background: transparent;
  border: none;
  font-size: 14px;
  line-height: 19px;
  color: #01147c;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.transaction-screen .tab-content {
  margin-bottom: 20px;
  box-shadow: none;
}

.payment-note {
  height: 100px;
  font-size: 14px;
}

.hub-section {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.hub-cards {
  padding-top: 20px;
  padding-bottom: 250px;
}

.loading-icon {
  position: fixed;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.transaction-details .border-top {
  padding-top: 10px;
}

input::-ms-reveal {
  display: none !important;
}

/* Hide password reveal button in MS Edge */
::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.payment-type-section {
  padding-top: 30px;
}

.data-search-input .p-input-icon-left {
  width: 335px;
  display: flex;
  align-items: center;
}

.data-search-input .p-input-icon-left .p-inputtext {
  padding: 7px 10px 7px 30px;
  width: 335px;
  font-size: 12px;
  display: flex;
  border-color: #01147c;
}

.p-input-icon-left .p-inputtext::placeholder {
  font-size: 12px;
  color: rgb(159, 159, 159);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.data-search-input .pi-search {
  font-size: 12px;
  margin-top: -6px;
}

.user-managment-search {
  display: flex;
  flex: 100%;
  justify-content: space-between;
}

.logo-section {
  display: flex;
  justify-content: center;
  padding-left: 40px;
  height: 35px;
  align-items: center;
  border-left: 4px solid white;
}

.partner-logo {
  width: 100%;
  max-width: 155px;
  max-height: 29px;
}

.partner-logo-bg {
  display: flex;
  background-color: white;
  min-width: 30px;
  max-width: 165px;
  max-height: 50px;
  min-height: 40px;
  align-items: center;
  padding: 0px 5px;
}

.partner-name {
  color: white;
  font-size: 16px;
  line-height: 20px;
  font-size: 400;
}

.p-button .p-component.notification-icon:hover {
  background: none;
}

.payment-section.fees-next {
  margin-top: 10px;
}

.refresh {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

.select-all-field {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.select-all-field .switch-style {
  margin-right: 10px;
}

.select-all-field p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #01147c;
}

.fees-card-wrapper {
  position: relative;
}

.row.card-error-msg {
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
}

.row.card-error-msg .blank-div {
  display: flex;
  flex: 10%;
  max-width: 95px;
  padding: 0px 0px;
}

.row.card-error-msg .blank-div-2 {
  display: flex;
  flex: 10%;
  max-width: 152px;
  padding: 0px 0px;
}

.row.card-error-msg .blank-div-3 {
  display: flex;
  flex: 10%;
  max-width: 155px;
  padding: 0px 0px;
}

.row.card-error-msg:nth-child(2) {
  max-width: 115px;
}

.row.card-error-msg .blank-div.toggle-country {
  display: flex;
  flex: 6% 1;
  min-width: 155px;
  background-color: transparent;
  margin: 0px 0px;
  padding: 10px 10px;
  border-radius: 4px;
}

.row.card-error-msg .error-msg-1 {
  display: flex;
  flex: 15%;
  max-width: 280px;
  margin-left: 13px;
}

.error-msg-2 {
  display: flex;
  flex: 22% 0;
  max-width: 299px;
  /* margin-left: 13px; */
}

.error-msg-3 {
  display: flex;
  flex: 30% 0;
  max-width: 299px;
  /* margin-left: 13px; */
}

/* .card-error-msg .col-md-3 .error-1
{
  position: absolute;
  right: 25px;
}

.card-error-msg .col-md-3 .error-2
{
  position: absolute;
  left: -11px;
} */
.card-error-1 {
  position: absolute;
  right: 33%;
  top: 0;
}

.card-error-2 {
  position: absolute;
  right: 5%;
  top: 0;
}

.facilitator-yes {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.percent-wrapper {
  position: relative;
}

 .feespercent {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
}

.user-dialog .feespercent{
  top: 2px;
  font-size: 12px;
}
.usermain-tab .data-search-input {
  width: 95%;
  margin: 0 auto;
}

.usermanagement-search .data-search-input {
  display: none;
}

.usermain-tab .data-search-input {
  display: block;
}

.refresh-btn {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 8px 20px;
  font-weight: 400;
  font-size: 14px;
}

.refresh-btn:focus {
  background-color: #01147c;
}

.text-green {
  color: #09d25d !important;
  font-weight: 600 !important;
}

.text-danger {
  font-weight: 600 !important;
}

.popup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-btn .btn.btn-back {
  margin: 10px;
}

.popup-btn .btn.btn-continue {
  margin: 10px;
}

.confirm-popup p {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #495057;
  font-weight: 400;
}

.popup-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 94%;
  padding-top: 0px;
  margin: 0 auto;
}

.popup-text .pi-info-circle {
  margin-right: 5px;
  margin-top: 2px;
}

.p-dialog-visible {
  display: flex;
}

.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: transparent;
  transition-property: background-color;
}

.F-Fee-main .button-section {
  padding-top: 9px;
  margin-bottom: -9px;
}

.F-Fee-main .facilitator-main {
  padding-top: 20px;
}

.AddFacilitator-div .button-section {
  padding-top: 9px;
  margin-bottom: -9px;
}

.AddFacilitator-div .facilitator-main {
  padding-top: 20px;
}

.user-tab .editbtn .pi-pencil,
.user-tab .editbtn .pi-trash {
  align-items: center;
  background: rgba(32, 48, 76, 0.09);
  border-radius: 100px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.ledgermain .user-tab {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 20px auto;
}

.send-currency {
  margin-bottom: 30px;
}

.ledgermain .dropdown-acc input {
  height: 32px;
  font-size: 14px;
}

.ledgermain .p-component.dropdown-acc {
  display: contents;
}

.ledger-screen {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 20px;
}

.ledger-table {
  margin-top: 15px;
}

.check-quotes-section {
  padding: 20px 0px;
}

.check-quotes-section .notes p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #01147c;
}

.fees-screen .button-section {
  margin-bottom: 135px;
}

.payment-screen .payment-section .col-md-2 {
  flex: 0 0 auto;
  width: 25%;
  position: relative;
}

.paymentsetting-readscreen .button-section {
  padding-top: 20px;
  margin-bottom: 40px;
}

.payment-informatione-main .error-msg {
  left: 15px !important;
}

.button-section .validate {
  padding-top: 20px;
}

.createpayment-screen .button-section {
  padding-top: 0px;
}

.SubmitpaymentScreen .transaction-screen {
  padding-bottom: 0px;
}

.SubmitpaymentScreen .transaction-details {
  margin-bottom: 20px;
}

.ledgermain .user-heading {
  justify-content: initial;
}

.resource-success-msg {
  font-weight: 600 !important;
  position: absolute;
  margin-top: 0px;
  font-size: 13px !important;
  line-height: 22px !important;
  margin-left: 17px;
  color: #22bb33;
}

.row.payment-section.Provide-the-delivery {
  margin-left: 54px;
}

.row.payment-section.partnership-drop {
  margin-left: 54px;
}

.user-heading.fees {
  margin-bottom: 0px;
  justify-content: end;
}

.heading-section.transaction {
  padding-bottom: 30px !important;
}

.awesome-text {
  color: #01147c;
  font-size: 22px;
}

.ledgermain {
  overflow: scroll;
  height: 100vh;
}

.educational-message p {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.7px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}

.error-msg.sla {
  font-size: 11px !important;
}

.right-tab-section.transaction-tab .button-section {
  padding-top: 0px;
}

.developer-resorce-Screen {
  padding-top: 10px !important;
}

.getquote-btn {
  margin-top: 42px;
}

.Cancel-popup {
  background-color: white;
  width: 22%;
  border-radius: 6px;
  padding: 20px 0px 10px;
}

.Cancel-popup h4 {
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 10px;
}

.Cancel-popup p {
  font-size: 16px;
  line-height: 22px;
}

.wallet-options {
  margin-left: 30px;
}

.wallet-options .form-group {
  display: flex;
  flex-direction: row;
}

.ledger-screen .user-heading .input-label p {
  margin-right: 10px;
}

.error-msg.report-error {
  position: absolute;
  left: 9px !important;
  bottom: -10px !important;
}

.reportpage .datatable-doc-demo {
  margin-top: 20px;
}

/* .transaction-table-scroll .p-datatable-table{
  width: 85vw;
  overflow-x: scroll;
  margin-bottom: 100px;
}

.transaction-table-scroll .transactionDetails-table{
  width: 85%;
} */

.reportpage .datatable-doc-demo .p-datatable .p-datatable-thead>tr>th {
  padding-left: 10px;
}

.right-tab-section.transaction-tab .contain-scroll>div>div {
  padding-bottom: 0px;
}

/* .transaction-table-scroll .p-sortable-column{
  width: 10%;
  } */

.Logo-wrappper {
  display: flex;
  max-width: 300px;
  min-width: 120px;
  max-height: 300px;
  min-height: 120px;
  align-items: center;
  padding: 0px 0px;
  border-radius: 6px;
  margin-left: 20px;
}

.user-input {
  align-items: baseline;
}

.btn.btn-continue.second-btn.send-invitebutton {
  font-weight: 500 !important;
}

.fxrate-btn {
  display: flex;
  justify-content: end;
  padding-right: 20px;
}

.fxratemain .right-tab-section .contain-scroll>div>div {
  padding-bottom: 0px;
}

.facilitator-main .p-fluid .p-inputtext {
  font-size: 12px;
  padding: 4px 10px;
}

.user-heading .btn.btn-back.hover-remove:hover {
  background-color: transparent;
  color: #01147c;
}

.usrmanagement .regiter-popup {
  width: 29% !important;
}

.pi.pi-info-circle {
  padding-right: 4px;
}

.usermanagement-search .p-inputtext.p-component {
  padding: 7px 10px 7px 30px;
  width: 335px;
  font-size: 12px;
  display: flex;
  border-color: #01147c;
}

/* .usrmanagement .confirm-popup p{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #01147c;
} */
.register-main .regiter-popup {
  width: 35%;
}

.feespopup #confirm-popup {
  width: 470px;
}

.wallet-card {
  min-height: 100px;
  /* width: 235px; */
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 12px;
  /* margin: 15px 10px; */
  /* padding: 20px; */
  border: none;
}

.availblebalance-div {
  margin-top: 10px;
  text-align: center;
  justify-content: center;
  display: grid;
}

.walletname {
  padding-top: 10px;
  text-align: center;
}

.wallet-card:hover {
  border: 1px solid rgb(179, 179, 179);
  cursor: pointer;
}

.wallet-card.active {
  background: rgb(244,244,244);
}

.walletrefresh {
  padding-bottom: 0px;
}

.walletrow {
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.error-msg li {
  list-style: none;
}

.dashboard-card {
  background: #f4f4f4;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 12px;
  min-height: 120px;
  border-radius: 20px;

}

.dashboardmain {
  margin-top: 20px !important;
  text-align: center;
  /* display: flex; */
  justify-content: center;

}

.dashboardmain .col-md-4 {
  margin-right: 30px;
  width: 30%;
  padding: 20px;
}

.text-black-address {
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 22px;
  margin-bottom: 0px;
  color: #01147c !important;
}

.dashboardvalue {
  font-weight: 600;
  font-size: 16px;
  color: black;
  line-height: 40px;

}

.dashboard-card:hover {
  border: 1px solid rgb(179, 179, 179);
  cursor: pointer;
}


.popup-screen .text-left p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}


.btn.btn-details {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 6px 10px;
  text-align: center;
  font-weight: 400 !important;
  font-size: 12px;
  max-width: 150px;

}

.Fxrate-popup {
  width: 30%;
  border-radius: 10px;
}

.Fxrate-popup p {
  font-size: 16px;
  line-height: 23px;

}

.Fxrate-popup .p-dialog-content {
  padding: 30px 30px 30px 30px;
  width: 100%;
  border-radius: 6px 6px 0px 0px;
}

.Fxrate-popup .p-dialog-footer button {
  margin: 0px 20px;
}

.Fxrate-popup .btn-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
  color: #01147c;
}

.Fxrate-popup .p-button:enabled:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;

}

.text-black-address.senderfees {
  margin-top: 20px;
}

.Imgupload-buttonbar .col-md-6 {
  display: flex;
}

.transactionmain .right-tab-section.transaction-tab .contain-scroll>div {
  max-height: calc(100vh - 184px) !important;
padding: 0px 45px;
  margin: 0 auto;
  padding-bottom: 80px;

}
.transactionmain .datatable-doc-demo .p-datatable-wrapper {
  margin-bottom: 0px;
}
.transactionmain .right-tab-section.transaction-tab .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 184px) !important;
  overflow-x: hidden !important;

  margin: 0 auto;
}

.transaction-screen-3 .user-heading{
  width: 95%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.transaction-screen.transaction-screen-3{
  width: 100%;
}

.bg-height .white-spinner {
  position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bg-height .white-spinner  .p-progress-spinner-circle{
  stroke: #ffffff !important;
}

.transaction-screen.create-payment-table 
.datatable-doc-demo .p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  word-break: break-all;
  padding: 8px 2px;
  font-size: 14px;
  line-height: 17px;
  font-family: "IBM Plex Sans" !important;
  background: transparent;
  padding-left: 5px;
  border-bottom: 1px solid rgb(222, 226, 230); 
}


.transaction-screen.create-payment-table  .datatable-doc-demo .p-datatable .p-datatable-thead>tr>th {
  font-size: 14px;
  line-height: 17px;
  color: #01147c;
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
  line-height: 19px;
  padding: 10px 2px;
  font-family: "IBM Plex Sans" !important;
  background-color: white;
  font-weight: 600;
  padding-left: 5px;
}
.button-section.create-payment-error-msg{
  display: flex;
  flex-direction: column;
}