@media (max-width: 2000px) and (min-width: 1601px) {
  .developer-screen-section {
    padding-left: 63px;
    padding-right: 76px;
  }

  .input-with-icon-cashpickup {
    background-position: left 30% center;
  }

  .input-with-icon-bank {
    background-position: left 29% center;
  }

  .input-with-icon-realtime {
    background-position: left 38% center;
  }

  .input-with-icon-mobilewallet {
    background-position: left 29% center;
  }

  .personal-details .button-section {
    padding-top: 55px;
  }
  .payment-screen .payment-section .col-md-2 {
    flex: 0 0 auto;
    width: 22%;
  }
  .row.payment-section.partnership-drop {
    margin-left: 72px;
  }
  .row.payment-section.Provide-the-delivery {
    margin-left: 72px;
  }
  .error-msg-2 {
    max-width: 290px;
  }
  .error-msg-send-2 {
    max-width: 260px;
  }
  .acc-screen .form-group .errormsg-limit {
    bottom: -18px;
  }
  .error-msg.report-error {
    position: absolute;
    left: 15px !important;
    bottom: -7px !important;
  }

  .Fees-details-card-4 {
    width: 74%;
  }
  .Fees-details-card-1 {
    width: 53%;
  }
  .Fees-details-card-3 {
    width: 66%;
  }
  .Fees-details-card-2 {
    width: 64%;
  }
  .regiter-popup.usermanagement {
    width: 23%;
  }
  .register-main .regiter-popup {
    width: 28%;
  }
  #sidebar ul .first-item {
    margin-top: 33px;
  }
  #sidebar ul li {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .ledgermain .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboardmain .col-md-4 {
    margin-right:50px ;
    width: 23%;
  }
  .dashboard-card {
    background: #eceef7;
  }
  .dashboardmain {
    margin-top: 10px !important;
  }
}

@media (min-width: 1601px) {
  /* 
    Tab section and main heading css start */
  .heading-section {
    padding: 15px;
  }
  .developer-resorce-Screen .heading-section {
    padding-top: 15px !important;
    padding-bottom: 0px;
  }
  .ledgermain .heading-section {
    padding-top: 15px !important;
    padding-bottom: 0px;
  }
  .text-header-purple {
    font-size: 22px;
    line-height: 41px;
  }

  .tab-content {
    top: 135px;
  }

  .payment-steps .text-blue {
    font-size: 17px;
  }

  .payment-line {
    width: 60px;
  }

  .fees-screen table th {
    font-size: 14px;
  }

  .transaction-screen
    .datatable-doc-demo
    .p-datatable
    .p-datatable-thead
    > tr
    > th {
    padding-left: 10px;
    font-size: 12px;
  }

  .transaction-screen
    .datatable-doc-demo
    .p-datatable
    .p-datatable-tbody
    > tr
    > td {
    padding-left: 10px;
    font-size: 12px;
  }

  /* 
    Tab section and main heading css end */

  .acc-screen .form-group input {
    height: 32px;
  }

  .acc-screen.payment-screen {
    padding-top: 20px;
  }

  .checkquote-cards {
    display: flex;
    width: 95%;
    padding-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .chechquotes-screen .card {
    flex: auto;
    margin: 10px 12px;
    max-width: 377px;
    min-width: 300px;
  }
  .text-color-checkquoet h4 {
    font-size: 14px;
    line-height: 17px;
  }

  .text-color-checkquoet p {
    font-size: 14px;
    line-height: 17px;
  }

  .text-color-checkquoet .recipient-amt {
    font-size: 14px;
    line-height: 17px;
  }

  .tab-pane {
    width: 100%;
  }
  .recevie-currency h3 {
    font-size: 22px;
  }
  .Fees-heading h2 {
    font-size: 24px;
  }
  .acccount-seeting-screen h2 {
    font-size: 22px;
  }

  .transaction-table-scroll .p-datatable-table {
    width: 100%;
    overflow-x: hidden;
    margin-bottom: 100px;
  }
.transactionmain .transaction-table-scroll .p-datatable-table {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 20px;
}
  .dashboard-card {
    background: #eceef7;
  }
  /* .transaction-table-scroll .transactionDetails-table{
  width: 100%;
}
.transaction-table-scroll .p-sortable-column{
width: 10%;
} */
}
@media (min-width: 1499px) {
  .acc-screen .form-group .second-doc-error {
    bottom: -18px;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  /* fees screen css */

  .Fees-details-card-2 .error-message-col {
    width: 28.5%;
  }
  .Fees-details-card-1 .error-message-col {
    width: 34.5%;
  }

  .Fees-details-card-3 .error-message-col {
    width: 29%;
  }
  .datatable-doc-demo .p-datatable .p-datatable-tbody > tr > td {
   
    font-size: 12px;
 
  }

  .transaction-screen.create-payment-table 
.datatable-doc-demo .p-datatable .p-datatable-tbody>tr>td {

  font-size: 12px;

}

  .Fees-details-card-4 .error-message-col {
    width: 26.5%;
  }
  .fees-card.country {
    flex: 6%;
    max-width: 120px;
  }

  .fees-card.currency {
    flex: 6%;
    max-width: 120px;
  }
  .Fees-details-card .fees-card p {
    font-size: 12px;
  }

  .Fees-details-card .fees-card {
    padding: 8px 10px 10px 10px;
  }
  .Fees-details-card .fees-card h3 {
    font-size: 13px;
  }

  .switch-field label {
    font-size: 12px;
    padding: 7px 10px;
    line-height: 2;
  }

  .switch-field input:checked + label {
    font-size: 12px;
    padding: 7px 12px;
    line-height: 2;
  }

  .fees-card input {
    font-size: 12px;
  }
  .toggle-country {
    min-width: 100px;
  }
  .row.card-error-msg {
    width: 99%;
  }

  .row.card-error-msg .blank-div {
    flex: 6%;
    max-width: 85px;
  }
  .row.card-error-msg .blank-div.toggle-country {
    flex: 6%;
    min-width: 160px;
  }

}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  .chechquotes-screen .card {
    /* flex: 30%; */
  }
  .form-group span {
    font-size: 12px;
  }
  .input-with-icon-cashpickup {
    background-position: left 20% center;
  }

  .input-with-icon-bank {
    background-position: left 18% center;
  }

  .input-with-icon-realtime {
    background-position: left 34% center;
  }

  .input-with-icon-mobilewallet {
    background-position: left 19% center;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1040px) {
  /* fees screen css */

  .fees-card.country {
    max-width: 100px;
  }

  .fees-card.currency {
    max-width: 100px;
  }

  .Fees-details-card .fees-card h3 {
    font-size: 11px;
  }

  .Fees-details-card .fees-card p {
    font-size: 12px;
  }
  .row.card-error-msg .error-msg-1 {
    padding: 0px;
  }

  .toggle-country {
    min-width: 100px;
  }
  .row.card-error-msg {
    width: 99%;
  }

  .row.card-error-msg .blank-div {
    flex: 6%;
    max-width: 75px;
  }
  .row.card-error-msg .blank-div.toggle-country {
    flex: 6%;
    min-width: 80px;
  }
  .switch-field label {
    font-size: 12px;
    padding: 7px 8px;
    line-height: 2;
  }
  .switch-field input:checked + label {
    font-size: 12px;
    padding: 7px 10px;
    line-height: 2;
  }
}

@media only screen and (max-width: 1039px) and (min-width: 900px) {
  /* fees screen css */

  .fees-card.country {
    flex: 6%;
    max-width: 100px;
  }

  .fees-card.currency {
    flex: 6%;
    max-width: 100px;
  }
  .Fees-details-card .fees-card p {
    font-size: 12px;
  }

  .Fees-details-card .fees-card {
    padding: 8px 8px 10px 8px;
  }
  .Fees-details-card .fees-card h3 {
    font-size: 12px;
  }

  .switch-field label {
    font-size: 12px;
    padding: 7px 7px;
    line-height: 2;
  }

  .switch-field input:checked + label {
    font-size: 12px;
    padding: 7px 10px;
    line-height: 2;
  }

  .fees-card input {
    font-size: 12px;
  }
  .toggle-country {
    min-width: 100px;
  }
  .row.card-error-msg {
    width: 99%;
  }

  .row.card-error-msg .blank-div {
    flex: 6%;
    max-width: 105px;
  }
  .row.card-error-msg .blank-div.toggle-country {
    flex: 6%;
    min-width: 100px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 900px) {
  /* navbar css */
  .navbar h5 {
    font-size: 17px;
  }

  .navbar {
    padding: 8px 10px;
  }

  .heading-section {
    padding: 15px;
  }

  .form-group span {
    font-size: 12px;
    line-height: 23px;
  }

  .acc-screen .form-group input {
    height: 26px;
    font-size: 12px !important;
  }

  /* navbar css end */

  /* side bar css */
  #sidebar {
    min-width: 170px;
    max-width: 200px;
  }

  #sidebar ul li a {
    text-align: left;
    font-size: 12px;
    /* margin-bottom: 0px !important; */
  }

  .sidebar-header .logo-white {
    width: 100%;
  }

  #sidebar ul.components {
    padding: 0px;
  }

  .btn.btn-cancel,
  .btn.btn-back {
    padding: 6px 9px;
    font-size: 11px;
  }

  .text-blue-address {
    font-size: 14px;
  }

  .register-address .form-check-label {
    font-size: 13px;
  }

  .input-label {
    line-height: 29px;
    font-size: 13px;
  }
  .ledgermain .input-label {
    line-height: 20px;
  }
  .info-section .text-area {
    font-size: 12px;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    height: 38px !important;
    line-height: 40px !important;
    font-size: 10px !important;
    color: #000000;
  }

  .user-dialog .form-control::placeholder {
    font-size: 10px;
    color: gray;
  }

  .account-details .form-control::placeholder {
    font-size: 10px !important;
    line-height: 17px;
  }

  .form-control::placeholder {
    font-size: 10px !important;
  }

  .p-autocomplete-dd .p-autocomplete-input::placeholder {
    font-size: 10px !important;
  }

  .button-section {
    padding-top: 20px;
  }

  .form-group .p-calendar .p-button {
    height: 26px;
  }

  .payment-button-section {
    padding-top: 45px;
  }

  .payment-screen h2 {
    font-size: 14px;
    line-height: 13px;
    padding: 5px;
  }

  .btn.btn-payment-selected {
    font-size: 13px;
    padding: 6px 35px;
  }

  .btn.btn-payment {
    font-size: 13px;
  }

  .payment-screen .form-check-label {
    font-size: 14px;
    margin-top: 7px;
  }

  .recevie-currency h3 {
    font-size: 18px;
  }

  .currency-details table th {
    font-size: 13px;
  }

  .p-tabview-title {
    font-size: 14px;
  }

  .paymentsetting-readscreen .read-text {
    font-size: 13px;
    line-height: 13px;
  }

  .paymentsetting-readscreen .read-only {
    width: 185px;
  }

  .paymentsetting-readscreen .read-only span {
    font-size: 13px;
  }

  .acccount-seeting-screen h2 {
    font-size: 16px;
  }

  .user-heading h2 {
    font-size: 15px;
  }

  .datatable-doc-demo .p-datatable .p-datatable-tbody > tr > td {
    font-size: 11px;
  }

  .datatable-doc-demo .p-datatable .p-datatable-thead > tr > th {
    font-size: 13px;
  }

  .yes-check {
    margin-right: 0px;
  }

  .createpayment-screen .form-check-label {
    /* width: 65px; */
    font-size: 12px !important;
  }

  .note-text {
    font-size: 7px !important;
  }

  .btn.btn-continue {
    font-size: 12px;
  }

  .text-header-purple {
    font-size: 16px;
  }

  .developer-resorce-Screen h2 {
    font-size: 20px;
  }

  .developer-resorce-Screen span {
    font-size: 14px;
  }

  .developer-resorce-Screen h1 {
    font-size: 14px;
  }

  .copyicon-size {
    top: 30px !important;
    height: 14px;
  }

  .payment-screen .yes-check {
    margin-right: 45px;
  }

  .acccount-seeting-screen table td .form-check-label {
    font-size: 12px;
  }

  .acccount-seeting-screen table th {
    font-size: 14px;
  }

  .namefield-border {
    padding: 5px 7px;
  }

  /* login css */

  .Login-form,
  .Register-form,
  .SetPassword-form {
    width: 480px;
    padding-top: 30px;
  }

  .login-page .logo-white {
    width: 118px;
  }

  .login-form-display h3 {
    font-size: 16px;
    line-height: 16px;
  }

  .login-form-display h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .login-form-input p {
    font-size: 13px;
    line-height: 13px;
  }

  .form-group.login-form-input input {
    padding: 6px;
    font-size: 11px;
  }

  .form-group.login-form-input input:active {
    padding: 6px;
    font-size: 13px;
  }

  .form-group.login-form-input input:-webkit-autofill,
  .form-group.login-form-input input:-webkit-autofill:hover,
  .form-group.login-form-input input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: none;
    border: 1px solid #ced4da;
    font-size: 11px;
  }

  form .btn-login {
    width: 170px;
    margin-bottom: 25px;
    font-size: 14px;
  }

  .forgot-password a {
    font-size: 13px;
    line-height: 13px;
  }

  .form-group.login-form-input {
    margin-bottom: 25px;
  }

  .new-here p {
    font-size: 16px;
  }

  .form-group.login-form-input.forget-pass-input {
    margin-bottom: 43px;
  }

  .Register-form p {
    font-size: 13px !important;
    line-height: 13px !important;
  }

  .register-login-text a {
    display: block;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 7px;
  }

  form .btn-register {
    font-size: 14px;
  }
  .dashboardmain .col-md-4 {
    width: 28%;
  }
  .dashboard-card {
    background: #eceef7;
  }
}

/* Tabconetnt.css */
@media only screen and (max-width: 1200px) and (min-width: 997px) {
  .tab-content {
    top: 0px;
  }

  .payment-steps .steps .step .payment-count {
    font-weight: 600;
    font-size: 12px;
    width: 25px;
    height: 25px;
  }

  .payment-tabcontent-main .text-blue {
    font-size: 11px;
  }

  .payment-tabcontent-main .payment-steps .steps .step .payment-count {
    font-size: 11px;
  }
  .payment-line {
    width: 20px;
  }

  .myfileupload-buttonbar .document-upload {
    width: 100px;
    font-size: 12px;
  }

  .fees-screen table th {
    font-size: 12px;
  }

  .checkquote-cards {
    display: flex;
    padding: 30px 10px;
    width: 95%;
    margin: 0 auto;
  }

  .chechquotes-screen .card {
    flex: 0 0 40%;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .select-all-field p {
    font-size: 16px;
  }
  .input-with-icon-cashpickup {
    background-position: left 14% center;
    font-size: 12px;
    background-size: 16px;
  }

  .input-with-icon-bank {
    background-position: left 12% center;
    font-size: 12px;
    background-size: 14px;
  }

  .input-with-icon-realtime {
    background-position: left 29% center;
    font-size: 12px;
    background-size: 16px;
  }

  .input-with-icon-mobilewallet {
    background-position: left 13% center;
    font-size: 12px;
    background-size: 10px;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 1041px) {
  .payment-steps .text-blue {
    font-size: 12px;
  }
  .row.card-error-msg .error-msg-1 {
    display: flex;
    flex: 9% 3;
    max-width: 278px;
  }
  .error-msg-2 {
    display: flex;
    flex: 33% 6;
    max-width: 280px;
  }
  .error-msg-3 {
    display: flex;
    flex: 31% 0;
  }
  .Cancel-popup {
    width: 30%;
  }
  .fees-card input {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1040px) and (min-width: 768px) {
  #sidebar {
    min-width: 110px;
    max-width: 160px;
  }
  .list-unstyled li a {
    font-size: 12px;
    padding: 6px 6px;
  }
  .payment-steps .text-blue {
    font-size: 10px;
  }
  .payment-line {
    border-bottom: 1px solid #01147c !important;
    min-height: 1px;
    margin: auto 2px;
    width: 25px;
  }

  .payment-steps .steps .step .payment-count {
    font-size: 10px;
    width: 22px;
    height: 22px;
  }

  .tab-pane {
    width: 100%;
    margin: 0 auto;
  }

  .form-group span {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
  }

  .info-section {
    padding-left: 25px !important;
    padding-right: 45px !important;
  }

  .acc-screen .form-group input {
    height: 24px;
    width: 100%;
    font-size: 9px !important;
    line-height: 16px;
    padding: 8px 10px;
  }

  .info-section-second {
    padding-right: 25px !important;
    padding-left: 40px !important;
  }

  .text-blue-address {
    font-size: 12px !important;
  }

  .register-address .form-check-label {
    margin-left: 10px;
    font-size: 8px;
  }

  .uploadimg {
    max-width: 110px;
    max-height: 110px;
  }
  .btn.btn-cancel,
  .btn.btn-back {
    padding: 6px 23px;
    width: 100px;
    font-size: 12px;
  }
  .btn.btn-continue {
    /* padding: 6px 22px;
  font-size: 12px; */
    padding: 9px 13px;
    font-size: 10px;
  }
  .input-label {
    font-size: 10px;
  }
  .info-section .text-area {
    max-height: 190px;
    min-height: 110px;
    font-size: 11px;
  }
  .acc-screen .form-group input::placeholder {
    font-size: 9px;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    font-size: 9px !important;
  }
  .payment-screen h2 {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 7px;
    padding: 5px 0px;
  }
  .payment-screen .form-check-label {
    font-size: 12px;
  }
  .form-check-input {
    width: 0.9em;
    height: 0.9em;
  }
  .payment-screen .form-check-input:checked[type="radio"] {
    background-size: 8px;
  }
  .btn.btn-payment {
    font-size: 12px;
  }
  .btn.btn-payment-selected {
    padding: 6px 35px;
    font-size: 12px;
  }
  .error-msg {
    font-size: 9px !important;
  }
  .button-section {
    padding-top: 0px !important;
  }
  .error-msg-small {
    font-size: 7px !important;
  }
  .form-group .p-calendar .p-button {
    height: 24px;
  }

  .Login-form,
  .Register-form,
  .SetPassword-form {
    /* width: 459px; */
    width: 408px;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .login-form-display h3 {
    font-size: 13px;
    line-height: 0px;
  }
  .login-form-display h2 {
    font-size: 21px;
  }
  form .btn-login {
    margin-bottom: 15px;
  }
  .form-group.password-input {
    margin-bottom: 26px;
  }
  .form-group.login-form-input {
    margin-bottom: 20px;
  }
  .Register-form p {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .register-login-text a {
    display: block;
    font-size: 17px;
    line-height: 0px;
    margin-bottom: 10px;
  }
  .form-group.login-form-input input {
    padding: 7px;
    font-size: 12px;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    line-height: 17px !important;
    font-size: 8px !important;
  }
  .p-tabview .p-tabview-nav li.p-unselectable-text .p-tabview-nav-link {
    font-size: 15px !important;
  }
  .datatable-doc-demo .p-datatable .p-datatable-thead > tr > th {
    padding-left: 14px;
  }
  .recevie-currency h3 {
    font-size: 17px;
  }
  .currency-details {
    margin-right: 40px;
  }
  .currency-details table th {
    font-size: 12px;
    padding: 10px 8px;
    padding-left: 23px;
  }
  .currency-details table td {
    font-size: 11px;
    padding-left: 25px;
  }
  .fees-discription {
    font-size: 10px;
  }

  .Fees-sending-payment h4 {
    font-size: 13px;
  }
  .btn-next {
    padding: 7px 20px;
    font-size: 12px;
  }
  .fees-card input {
    font-size: 12px;
  }
  .paymentsetting-readscreen .read-only {
    width: 140px;
    font-size: 11px;
  }
  .paymentsetting-readscreen .read-text {
    font-size: 11px;
  }
  .paymentsetting-readscreen .read-only span {
    font-size: 9px;
    padding: 5px 9px;
  }

  .acccount-seeting-screen h2 {
    font-size: 13px;
    line-height: 20px;
  }
  .acccount-seeting-screen table th {
    font-size: 13px;
  }
  .acccount-seeting-screen table td .form-check-label {
    font-size: 11px;
  }
  .datatable-doc-demo .p-datatable .p-datatable-tbody > tr > td {
    padding: 7px 15px;
    font-size: 11px;
    padding-left: 6px;
  }
  .datatable-doc-demo .p-datatable .p-datatable-thead > tr > th {
    padding: 10px 7px;
    /* padding: 10px 44px; */
  }

  .user-heading h2 {
    font-size: 11px;
    line-height: 16px;
  }
  /* .payment-steps .steps .step {
  display: table-cell ;
}
.payment-steps .steps {
  display:table;
} */
  .user-dialog .p-dialog-content h2 {
    font-size: 16px;
  }
  .user-dialog {
    width: 65%;
  }
  .createpayment-screen .address-heading {
    padding: 0px 25px;
  }
  .developer-header-color {
    font-size: 15px;
  }
  .developer-resorce-Screen h2 {
    font-size: 20px;
  }
  .developer-resorce-Screen p {
    font-size: 12px;
    line-height: 16px;
  }
  .developer-resorce-Screen h1 {
    font-size: 15px;
  }
  .createpayment-screen .form-group .form-check-label {
    font-size: 11px;
  }
  .myfileupload-buttonbar .document-upload {
    width: 70px;
    font-size: 12px;
    height: 26px;
  }
  .text-color-checkquoet h4 span {
    font-size: 12px;
    line-height: 28px;
  }
  .text-color-checkquoet .recipient-amt {
    font-size: 12px;
  }
  .chechquotes-screen .card {
    padding-bottom: 23px;
    flex: 0 0 45%;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .checkquote-cards {
    padding: 20px 0px;
  }
  .collapse:not(.show) {
    display: flex;
  }
  .navbar {
    padding: 0px 10px 0px 0px;
  }
  .login-form-input p {
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 10px;
    margin-bottom: 9px;
    color: #ffff;
    letter-spacing: 0.5px;
  }
  .forgot-password a {
    font-size: 14px;
  }
  .new-here p {
    font-size: 18px;
    line-height: 8px;
    margin-bottom: 5px;
    cursor: auto !important;
  }
  .Sigin-page::before {
    background-size: 185px;
    background-image: url(../images/Login-left-img.svg),
      url(../images/instarails-elements.svg),
      url(../images/login-bottom-img.svg) !important;
  }

  .profileimg-mr img {
    width: 18px;
    height: 18px;
  }
  .sidebar-mb-4 {
    margin-bottom: 0.5rem;
    margin-top: 14px !important;
  }
  .list-unstyled li img {
    width: 16px !important;
    height: 13px !important;
  }
  #sidebar ul.components {
    padding: 20px 7px;
  }
  .partner-logo {
    height: 29px;
  }

  .p-menu .p-menuitem-link {
    font-size: 14px;
  }
  .acc-screen.contact-info .Phone-input {
    height: 24px;
  }
  .requiredBlue-img {
    height: 9px !important;
  }
  .popup-screen .p-dialog-content .awesome-text {
    font-size: 15px !important;
    color: #01147c !important;
    line-height: 22px !important;
  }
  .popup-screen .text-center p {
    font-size: 15px;
    line-height: 20px;
  }
  .btn.user-ok {
    width: 80px;
    font-size: 12px;
  }
  .Fees-details-card .fees-card p {
    font-size: 12px;
  }
  .Fees-details-card .fees-card h3 {
    font-size: 12px;
  }
  .regiter-popup {
    width: 48%;
  }
  #confirm-popup {
    width: 370px;
  }
  #confirm-popup .p-dialog-content span {
    font-size: 14px;
  }
  #confirm-popup .p-dialog-footer .p-button {
    padding: 2px 27px;
  }
  .btn.btn-getquote {
    font-size: 10px;
  }
  .chechquotes-screen .card .card-body {
    padding: 12px 4px 0px 5px;
  }
  .p-dialog-mask {
    width: 114%;
  }
  .input-with-icon-cashpickup {
    background-position: left 14% center;
    font-size: 12px;
    background-size: 16px;
  }

  .input-with-icon-bank {
    background-position: left 12% center;
    font-size: 12px;
    background-size: 14px;
  }

  .input-with-icon-realtime {
    background-position: left 29% center;
    font-size: 12px;
    background-size: 16px;
  }

  .input-with-icon-mobilewallet {
    background-position: left 13% center;
    font-size: 12px;
    background-size: 10px;
  }
  .error-msg-2 {
    display: flex;
    flex: 28% 0;
    max-width: 317px;
  }
  .Cancel-popup {
    width: 30%;
  }
}
