@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;1,100;1,200&family=Inter:wght@500;700&family=Open+Sans:wght@600&display=swap";

a {
  text-decoration: none !important;
  background-color: transparent;
}



.lh-40 {
  line-height: 40px !important;
}

.text-white {
  font-weight: 700;
  font-size: 23px;
  line-height: 32px;
}

.form-group span {
  font-weight: 700;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.Sigin-page {
  background: linear-gradient(180deg, #1600fd 0%, #01147c 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sigin-page::before {
  content: "";
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  background-image: url(../../../assets/images/Login-left-img.svg),
    url(../../../assets//images/instarails-elements.svg),
    url(../../../assets/images/login-bottom-img.svg) !important;
  background-position: top left, top right, bottom left;
  background-repeat: no-repeat;
  position: absolute;
}

.Login-form,
.Register-form,
.SetPassword-form {
  background: linear-gradient(0deg, #1f1d9a 0%, #8693f156 100%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  position: relative;
  padding-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
  width: 660px;
  color: #f5f5f5;
  opacity: 127%;
  padding-bottom: 30px;
}

.signin-form-display {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IRLOGOText {
  font-weight: 700;
  font-size: 28px;
}

.IR-text {
  font-family: 'Space Grotesk', sans-serif;
}

.payment-network-text {
  font-size: 24px !important;
}

.form-group.login-form-input input {
  background-color: transparent !important;
  box-shadow: 2px 4px 10px rgb(0 0 0 / 12%) !important;
  border-radius: 4px !important;
  color: white;
  padding: 7px 10px;
  font-size: 14px;
  width: 350px;
  line-height: 17px;
  letter-spacing: 0.6px;
  border: 1px solid #ced4da;
  font-family: "IBM Plex Sans" !important;
}

.form-group.login-form-input input:-webkit-autofill,
.form-group.login-form-input input:-webkit-autofill:hover,
.form-group.login-form-input input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: none;
  border: 1px solid #ced4da;
}
.username-form {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}



.username-form form {
  width: 351px;
}

.form-group.form-check.login-checkbox {
  margin-left: 20% !important;
}

.text-white-link {
  color: #f5f5f5;
}

.form-check-input {
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #ffffff;
}

/* .login-form-input.form-check-input::placeholder {
  color: gray !important;
} */
.eyeicon {
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
}

.form-group .form-check-label {
  line-height: 22px !important;
}

.bi-eye-slash::before {
  top: 49%;
  position: absolute;
  right: 32%;
  color: #000000;
}

.password-input-field {
  position: relative;
}

.login-error-msg {
  color: red !important;
  font-weight: 400 !important;
  position: absolute;
  margin-top: 0px;
  font-size: 12px !important;
  line-height: 22px !important;
}
.login-error-msg-password {
  color: red !important;
  font-weight: 400 !important;
  position: absolute;
  margin-top: 0px;
  font-size: 12px !important;
  line-height: 14px !important;
}
.Register-form p {
  color: #f5f5f5;
  font-size: 17px !important;
  line-height: 23px !important;
}

.register-thanks {
  font-size: 20px !important;
}

.register-login-text a {
  display: block;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 10px;
}

.Register-form a:hover {
  color: rgb(167, 176, 253);
}

/* .btn-register.p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute !important;
  left: 30% !important;
}
.btn-login.p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute !important;
  left: 35% !important;
} */
form .btn-register {
  width: 180px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
  font-family: "IBM Plex Sans";
  position: relative;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.6px;
  color: #ffff;
  border: none;
  padding: 6px 0px;
  background: transparent;
  border: 1px solid rgb(167, 176, 253) !important;
}

form .btn-login {
  width: 180px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  font-family: "IBM Plex Sans";
  position: relative;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.6px;
  color: #ffff;
  border: none;
  padding: 6px 0px;
  background: transparent;
  border: 1px solid rgb(167, 176, 253) !important;
}

.btn-login .p-button-icon {
  position: absolute;
  left:35px;
  top: 11px;
}

.btn.btn-register .p-button-icon {
  position: absolute;
  left: 30px;
  top: 11px;
}

.username-form form .btn-register:hover {
  box-shadow: none;
  color: #01147c;
  background: rgb(167, 176, 253);
}

.username-form form .btn-login:hover {
  box-shadow: none;
  color: #01147c;
  background: rgb(167, 176, 253);
}

.p-button:focus {
  box-shadow: none;
}

.p-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

.btn-register.p-butto:hover {
  font-family: "IBM Plex Sans" !important;
  background: #f7f7f7 !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #01147c !important;
  width: 100% !important;
  padding: 6px 0px !important;
}

.sign-in-text {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #ffffff;
}

.text-white-pass {
  font-size: 25px;
  font-weight: 700;
  line-height: 37px;
}

.p-component {
  font-family: "IBM Plex Sans" !important;
}



.p-dialog hr {
  width: 80%;
  border-top: 3px solid #01147c !important;
  margin-left: 11%;
}

.p-dialog-draggable .p-dialog-header {
  display: none !important;
}

.new-here {
  cursor: pointer;
}

.login-page {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid rgb(167, 176, 253);
}

.login-page .logo-white {
  width: 150px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.login-form-display h2 {
  font-size: 25px;
  line-height: 31px;
  margin-bottom: 8px;
}

.login-form-display h3 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 25px;
}

.form-group.login-form-input {
  margin-bottom: 30px;
}

.login-form-input p {
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-bottom: 9px;
  color: #ffff;
  letter-spacing: 0.5px;
}

.login-form-input img {
  width: 13px;
  margin-right: 7px;
}

.form-group.password-input {
  margin-bottom: 40px;
}

.forgot-password a {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
}

.forgot-password {
  margin-bottom: 18px;
}

.forgot-password a:hover {
  color: rgb(167, 176, 253);
}

.new-here p {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 2px;
  cursor: auto !important;
}



.p-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.signin-form-display h4 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 10px;
}

.signin-form-display .second-title {
  margin-bottom: 20px;
}

form .save-btn {
  margin-bottom: 40px;
}

.form-group.login-form-input.confirm-pass {
  margin-bottom: 40px;
}

.link-expired-message .link-expire {
  font-size: 22px;
  padding-bottom: 20px;
  color: #fff;
  line-height: 28px;
  text-align: center;
}

.btn-login.save-btn .p-button-icon {
  position: absolute;
  left: 16px;
  top: 11px;
}

.btn.btn-register-ok {
  width: 100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  font-family: "IBM Plex Sans";
  position: relative;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.6px;
  margin-top: 10px;
  color: #01147c;
  border: none;
  padding: 6px 0px;
  background: transparent;
  border: 1px solid #01147c !important;
}

.btn.btn-register-ok:hover,
.btn.user-ok:hover {
  color: #fff !important;
  background: #01147c !important;
  border: 1px solid #01147c !important;
}

.btn.user-ok {
  width: 100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  font-family: "IBM Plex Sans";
  position: relative;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.6px;
  margin-top: 10px;
  color: white;
  border: none;
  padding: 6px 0px;
  background: #01147c;
  border: 1px solid #01147c !important;
}
.btn.user-ok:disabled {
  background: #01147c;
  color: white;
}
.form-group.login-form-input.forget-pass-input {
  margin-bottom: 50px;
}

.forget-pass-popup.p-button:enabled:hover {
  color: #6366f1;
  background-color: transparent;
  border: 1px solid #6366f1;
}

form .reset-btn {
  width: 200px;
}

.btn-submit .p-button-icon {
  position: absolute;
  left: 34px;
  top: 11px;
}
.password-input-icon {
  position: relative;
  color: gray;
}
.form-group.login-form-input input:active {
  background-color: #fff !important;
}
.form-group.login-form-input input:focus {
  background-color: #fff !important;
  color: black;
}
.username-form.register-form{
  width: 350px;
}