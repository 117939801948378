.wrapper {
  display: block;
}

#sidebar {
  min-width: 210px;
  max-width: 210px;
  background: #01147c;
  color: #fff !important;
  transition: all 0.3s;
  font-style: normal;

}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}



#sidebar ul li .active{
  background-color: #7386d5;
}
#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  color: #fff !important;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  margin-bottom: 5px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  padding: 20px 20px;
}

#sidebar .sidebar-header strong {
  display: none;
}

#sidebar ul.components {
  /* padding: 20px 0; */
}
#sidebar ul li {
  margin-top: 0;
  margin-bottom: 17px;
}
#sidebar ul li a:hover {
  background-color: #7386d5;
}

#sidebar ul li a i {
  margin-right: 10px;
}


#sidebar ul .first-item
{
  margin-top: 20px;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}


.list-unstyled li a {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 17px;
  line-height: 23px;
  font-weight: 400;
  padding: 7px 20px;

}
.list-unstyled li a span{
  font-family: 'Space Grotesk', sans-serif;
}
.list-unstyled li img {
  width: 18px;
  height: 16px;
}
ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

.togle-button {
  display: flex;
  width: 100%;
  height: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}
.ml-10 {
  margin-left: 10px;
}
.sidebar-header .logo-white {
 width: 100%;
 max-width: 155px;
  cursor: pointer;
}
.sidebar-mb-4{
  margin-bottom: 1.5rem;
}
@media (min-width: 1600px) {
  #sidebar .sidebar-header {
    padding: 17px 20px;
  }
 
}

.sidebar-disabled {
  opacity: .7;
  pointer-events: none;
}
.logout{
  list-style: none;
    padding-left: 20px;
}