@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;1,100;1,200&family=Inter:wght@500;700&family=Open+Sans:wght@600&display=swap";
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 10px 10px 10px 0px;
  background: #01147c;
  border: none;
  border-radius: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 100%;
  width: 100%;

  position: relative;
}



.pi.pi-bell {
  align-items: center;
  color: white;
  margin-right: 0px;
  position: relative;
  margin-left: 30px;
  padding: 0px;
}
.pi.pi-bell:hover {
  color: rgb(193, 239, 239);
}
.navbar h5 {
  font-size: 20px;
  line-height: 36px;
}
.navbar button {
  background: transparent;
  border: none;
  display: flex;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}
.togle-button {
  display: flex;
  width: 100%;
}

.has-search .form-control {
  background: rgba(249, 251, 252, 0.4);
  border: 1px solid rgba(253, 248, 248, 0.88);
  box-shadow: 0px 4px 8px 3px rgba(85, 60, 139, 0.24);
  border-radius: 4px;
  width: 500px;
  font-size: 14px;
  line-height: 17px;
  padding: 7px 10px;
}

.has-search .form-control::placeholder {
  color: rgb(213, 213, 213) !important;
  font-size: 14px;
  line-height: 17px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.profileimg-mr img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.profileimg-mr {
  margin-right: 20px;
  height: 20px;
  margin-left: 33px;
}
.notification-img {
  margin-right: 20px;
}
.notification-img img {
  height: 33px;
}
.p-menu .p-submenu-header {
  padding: 0rem 0rem !important;
}
@media (min-width: 1600px) {
  .navbar {
    padding: 10px 10px 10px 0px;
  }
  .navbar h5 {
    font-size: 22px;
    line-height: 28px;
  }
}

.notification-dropdown {
  position: absolute;
  display: block;
  width: 350px;
  height: 200px;
  background-color: #eaeaeaf1;
  right: 4%;
  top: 55px;
  border-radius: 6px;
  z-index: 99999;
}
.notification-dropdown::before {
  content: "";
  display: block;
  position: absolute;
  left: 90%;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid #eaeaeaf1;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.notification-bar {
  position: relative;
  cursor: pointer;
}
.notification-count {
  position: absolute;
  right: -3px;
  top: 0px;
  z-index: 1;
  font-size: 10px;
  color: rgb(0, 42, 255);
  font-weight: 700;
  background: #f5f5f5;
  width: 15px;
  height: 15px;
  font-weight: 400;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.notification-bar .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  border-color:none;
}